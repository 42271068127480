//backend/config.js
import crypto from 'crypto';
import dotenv from 'dotenv';

dotenv.config();

const config = {
  port: process.env.PORT || 8080,
  socketPort: process.env.SOCKET_PORT || 8090,
  mongodbUri: process.env.MONGODB_URI,
  mongodbUser: process.env.MONGODB_USER,
  mongodbPassword: process.env.MONGODB_PASSWORD,
  redisHost: process.env.REDIS_HOST,
  redisPort: process.env.REDIS_PORT,
  redisPassword: process.env.REDIS_PASSWORD,
  secretKey: process.env.SECRET_KEY,
  sessionSecret: process.env.SESSION_SECRET,
  jwtSecret: process.env.JWT_SECRET,
  adminPortfolioId: process.env.ADMIN_PORTFOLIOID,
  bankPortfolioId: process.env.BANK_PORTFOLIOID,
};

export const secretKey = process.env.SECRET_KEY || crypto.randomBytes(32).toString('hex');
export const apiBaseUrl = 'https://zexchange.me';
export const apiBaseSocket = 'wss://zexchange.me';
export const apiBaseParcel = 'https://zexchange.me:8888';
export const PortfolioABC = process.env.BANK_PORTFOLIOID || '660532b63896f8313840b32e';


export default config;


